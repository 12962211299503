import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ApiService from '../Services/ApiService';

function Accounts() {

    const [accounts, setAccounts] = useState([]);

    async function getAccounts() {

        let accounts = await ApiService.getSMTPAccounts();
        setAccounts(accounts);

    }

    useEffect(() => {
        getAccounts();
    }, []);

    return (
        <div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-10">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title mb-4">Accounts
                                    <span className="text-danger small ms-2">{accounts.length}</span>
                                </h5>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Password</th>
                                            <th>SMTP Host</th>
                                            <th>SMTP Port</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Map over the accounts array and render a table row for each account */}
                                        {accounts.map((account, i) => (
                                            <tr key={account._id}>
                                                <td>{i + 1}</td>
                                                <td>{account.email}</td>
                                                <td>{account.password}</td>
                                                <td>{account.smtp_host}</td>
                                                <td>{account.smtp_port}</td>
                                                <td>
                                                    <div className='d-flex'>
                                                        <NavLink
                                                            to={`/accounts/${account._id}/edit`}
                                                            className='btn btn-outline-success btm-sm'>
                                                            <i className="fas fa-edit"></i>
                                                        </NavLink>

                                                        <NavLink
                                                            to={`/accounts/${account._id}/test`}
                                                            className='btn btn-outline-info btm-sm ms-2'>
                                                            <i className="far fa-envelope"></i>
                                                        </NavLink>
                                                    </div>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <NavLink to="/accounts/new" className='btn btn-primary'>
                                    Add new account
                                </NavLink>

                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    )

}


export default Accounts;