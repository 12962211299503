import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";
import { useNavigate, useParams } from "react-router-dom";

function AccountsEdit() {

    const navigate = useNavigate();
    const { id } = useParams();

    const [account, setAccount] = useState({
        email: '',
        password: '',
        smtp_host: '',
        smtp_port: ''
    });

    async function getAccount() {
        let account = await ApiService.getSMTPAccountDetail(id);
        setAccount(account);
    }

    useEffect(() => {
        getAccount();
    }, []);


    async function updateAccount() {

        if (account.email === '') {
            alert('Email is required');
            return;
        }

        if (account.password === '') {
            alert('Password is required');
            return;
        }

        if (account.smtp_host === '') {
            alert('SMTP Host is required');
            return;
        }

        if (account.smtp_port === '') {
            alert('SMTP Port is required');
            return;
        }

        let { message } = await ApiService.updateSMTPAccount(account);
        alert(message);
        navigate('/accounts');
    }


    return (
        <div className="container">
            <h1>AccountsNew</h1>

            <div className="row">
                <div className="col-6">
                    <div className="card">
                        <div className="card-header">
                            New account
                        </div>
                        <div className="card-body">

                            <div className="mt-3">
                                <label className="form-label">Account (email)</label>
                                <input type="text"
                                    value={account.email}
                                    onChange={(e) => setAccount({ ...account, email: e.target.value })}
                                    className="form-control" />
                            </div>

                            <div className="mt-3">
                                <label className="form-label">Password</label>
                                <input type="text"
                                    value={account.password}
                                    onChange={(e) => setAccount({ ...account, password: e.target.value })}
                                    className="form-control" />
                            </div>

                            <div className="mt-3">
                                <label className="form-label">SMTP Host</label>
                                <input type="text"
                                    value={account.smtp_host}
                                    onChange={(e) => setAccount({ ...account, smtp_host: e.target.value })}
                                    className="form-control" />
                            </div>

                            <div className="mt-3">
                                <label className="form-label">SMTP Port</label>
                                <input type="text"
                                    value={account.smtp_port}
                                    onChange={(e) => setAccount({ ...account, smtp_port: e.target.value })}
                                    className="form-control" />
                            </div>

                            <div className="mt-3">
                                <button
                                    onClick={updateAccount}
                                    className="btn btn-success">Update</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    );
}

export default AccountsEdit;