import { useEffect, useState } from "react"
import ApiService from '../Services/ApiService';

function Messages() {

    let [messages, setMessages] = useState([]); // state
    let [selectedMessage, setSelectedMessage] = useState({
        subject: '',
        body: ''
    });
    let [selectedOption, setSelectedOption] = useState(0);


    const getMessages = async (noSelect) => {
        let messages = await ApiService.getMessages();
        if (messages.length === 0) {
            return;
        }
        setMessages(messages);
        if (noSelect === true) return;

        setSelectedMessage(messages[0]);

    }

    useEffect(() => {
        getMessages();
    }, []);

    const handleOptionChange = (e) => {

        if (e.target.value === 'new') {

            setSelectedOption('new');

            setSelectedMessage({
                subject: '',
                body: ''
            });

            return;
        }

        let index = parseInt(e.target.value);
        let selectedMessage = messages[index];
        if (selectedMessage) {
            setSelectedMessage(selectedMessage);
            setSelectedOption(index);
        } else {
            alert('No message selected');
        }
    }

    const handleSubjectChange = (e) => {
        let subject = e.target.value;
        setSelectedMessage({ ...selectedMessage, subject });
    }

    const handleBodyChange = (e) => {
        let body = e.target.value;
        setSelectedMessage({ ...selectedMessage, body });
    }

    const handleSave = async () => {

        if (selectedMessage._id) {
            let updatedMessage = await ApiService.updateMessage(selectedMessage);
        } else {
            let savedMessage = await ApiService.saveMessage(selectedMessage);
        }
        getMessages(true);

        alert('Message saved');

    }

    return (
        <div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-8">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title mb-4">Messages
                                <span className="text-danger small ms-2">{messages.length}</span>
                                </h5>

                                <div className="form-group mb-3">
                                    <label className="fs-14" htmlFor="selectOption">Select an option:</label>
                                    <select className="form-control" id="selectOption" value={selectedOption} onChange={handleOptionChange}>
                                        {messages.map((option, index) => {
                                            return <option key={index} value={index}>Message {index + 1}</option>
                                        })}
                                        <option value="new">Create new message</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="fs-14" htmlFor="inputSubject">Subject:</label>
                                    <input type="text" className="form-control" id="inputSubject" value={selectedMessage.subject} onChange={handleSubjectChange} />
                                </div>

                                <div className="form-group mb-3">
                                    <label className="fs-14" htmlFor="inputBody">Body:</label>
                                    <textarea className="form-control"
                                        id="inputBody"
                                        rows="6" value={selectedMessage.body} onChange={handleBodyChange}></textarea>
                                </div>
                                <button className="btn btn-primary" onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    )
}

export default Messages