import { useState } from "react";
import PubSub from 'pubsub-js';


function BasicAlert() {

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('message');
    const [title, setTitle] = useState('title');

    PubSub.subscribe('show.basic.notification', (msg, data) => {
        setShow(true);
        setMessage(data.message);
        setTitle(data.title);
        setTimeout(() => {
            setShow(false);
        }, 5000);
    });



    return (
        <div className={`notification basic-notification ${show ? 'active' : ''}`}>
            
            <div className="notification-title">{title}</div>
            <div className="fs-14">{message}</div>
        </div>
    )

}

export default BasicAlert;