const Requests = {
    get: async (url) => {
        try {
            const response = await fetch(url)
            const data = await response.json();
            return data
        } catch (err) {
            console.log(`(error) - in http get: ${url} - ${err}`, err);
            return err;
        }

    },
    post: async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(`(error) - in http get: ${url} - ${err}`, err);
            return err;
        }
    },
    put: async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(`(error) - in http get: ${url} - ${err}`, err);
            return err;
        }
    }
}

export default Requests