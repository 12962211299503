import logo from './logo.svg';
import './App.css';
import Sidebar from './Sidebar/Sidebar';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import Import from './Import/Import';
import Results from './Results/Results';
import Uploads from './Uploads/Uploads';
import UploadDetail from './Uploads/UploadDetail';
import Messages from './Messages/Messages';
import Accounts from './Accounts/Accounts';
import AccountsNew from './Accounts/AccountsNew';
import AccountsEdit from './Accounts/AccountsEdit';
import AccountSendEmail from './Accounts/AccountsSendEmail';
import GeneralConfig from './GeneralConfig/GeneralConfig';
import BasicAlert from './Alerts/BasicAlert';
import LoadingAlert from './Alerts/LoadingAlert';

function App() {
  return (
    <main>
      <BrowserRouter>
        <BasicAlert />
        <LoadingAlert />
        <Sidebar />
        <div style={{ flex: 1, background: '#F4F7FA', overflowY: 'scroll' }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="import" element={<Import />} />
            <Route path="uploads/:id" element={<UploadDetail />} />
            <Route path="uploads" element={<Uploads />} />
            <Route path="messages" element={<Messages />} />
            <Route path="configuration" element={<GeneralConfig />} />
            <Route path="accounts" element={<Accounts />} />
            <Route path="accounts/new" element={<AccountsNew />} />
            <Route path="accounts/:id/edit" element={<AccountsEdit />} />
            <Route path="accounts/:id/test" element={<AccountSendEmail />} />
          </Routes>
        </div>

      </BrowserRouter>

    </main >
  );
}

export default App;
