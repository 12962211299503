import { useState } from "react";
import PubSub from 'pubsub-js'


function LoadingAlert() {

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('message');
    const [title, setTitle] = useState('title');

    PubSub.subscribe('show.loading.notification', (msg, data) => {
        let { message, title, show } = data;
        setShow(show);
        setMessage(message);
        setTitle(title);
     
    });



    return (
        <div className={`notification basic-notification ${show ? 'active' : ''}`}>

            <div className="notification-title">
                <i class="fas fa-spinner fa-spin me-2"></i>
                {title}
            </div>
            <div className="fs-14">{message}</div>
        </div>
    )

}

export default LoadingAlert;