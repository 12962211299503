import Requests from "./Requests";

const API_URL = 'https://api.nobouncy.io/api';
// const API_URL = 'http://localhost:3000/api';

const UPLOAD_CSV_EMAILS = '/nobouncy/upload/csv';
const GET_UPLOADS = '/nobouncy/uploads';
const GET_UPLOAD_DETAIL = '/nobouncy/uploads/detail';

const GET_MESSAGES = API_URL + '/nobouncy/messages/get';
const SAVE_MESSAGE = API_URL + '/nobouncy/messages/save';
const UPDATE_MESSAGE = API_URL + '/nobouncy/messages/update';

const START_INITIAL_TIMER = API_URL + '/nobouncy/timer/initial';
const START_BOUNCE_TIMER = API_URL + '/nobouncy/timer/bounce';

const GET_LATEST_INITIAL_EMAIL_SENT = API_URL + '/nobouncy/emails/initial/latest';
const GET_LATEST_TIMERS_LOGS = API_URL + '/nobouncy/timer/logs';

const CREATE_SMTP_ACCOUNT = API_URL + '/nobouncy/accounts/smtp/create';
const GET_SMTP_ACCOUNTS = API_URL + '/nobouncy/accounts/smtp/get';
const GET_SMTP_ACCOUNT_DETAIL = API_URL + '/nobouncy/accounts/smtp/detail';
const UPDATE_SMTP_ACCOUNT = API_URL + '/nobouncy/accounts/smtp/update';
const SEND_SMTP_EMAIL = API_URL + '/nobouncy/accounts/smtp/test';

const GET_AVAILABLE_ACCOUNTS = API_URL + '/nobouncy/accounts/available';
const ACTIVATE_UPLOAD = API_URL + '/nobouncy/uploads/activate';

const GET_DEFAULT_CONFIGURATION = API_URL + '/nobouncy/config/general/get';
const UPDATE_DEFAULT_CONFIGURATION = API_URL + '/nobouncy/config/general/update';

const ApiService = {

    uploadFile: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return fetch(API_URL + UPLOAD_CSV_EMAILS, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => data)
            .catch(error => error);
    },
    getUploads: () => {
        return Requests.get(API_URL + GET_UPLOADS);
    },
    getUploadDetail(id) {
        return Requests.get(API_URL + GET_UPLOAD_DETAIL + '/' + id);
    },
    getMessages: () => {
        return Requests.get(GET_MESSAGES);
    },
    saveMessage: (message) => {
        return Requests.post(SAVE_MESSAGE, message);
    },
    updateMessage: (message) => {
        return Requests.put(UPDATE_MESSAGE, message);
    },
    startInitialTimer: () => {
        return Requests.post(START_INITIAL_TIMER, {})
    },
    startBounceTimer: () => {
        return Requests.post(START_BOUNCE_TIMER, {})
    },
    getLatestInitialEmailsSent: () => {
        return Requests.get(GET_LATEST_INITIAL_EMAIL_SENT);
    },
    getLatestTimersLogs: () => {
        return Requests.get(GET_LATEST_TIMERS_LOGS);
    },
    createSMTPAccount(account) {
        return Requests.post(CREATE_SMTP_ACCOUNT, account);
    },
    getSMTPAccounts() {
        return Requests.get(GET_SMTP_ACCOUNTS);
    },
    getSMTPAccountDetail(id) {
        return Requests.get(`${GET_SMTP_ACCOUNT_DETAIL}?_id=${id}`);
    },
    updateSMTPAccount(account) {
        return Requests.post(UPDATE_SMTP_ACCOUNT, account);
    },
    sendSMTPEmail(emailParams) {
        return Requests.post(SEND_SMTP_EMAIL, emailParams);
    },
    getDefaultConfiguration() {
        return Requests.get(GET_DEFAULT_CONFIGURATION);
    },
    updateDefaultConfiguration(config) {
        return Requests.post(UPDATE_DEFAULT_CONFIGURATION, config);
    },
    getAvailableAccounts() {
        return Requests.get(GET_AVAILABLE_ACCOUNTS);
    },
    activateUpload(upload_id, active) {
        return Requests.post(ACTIVATE_UPLOAD, { upload_id, active })
    }

}

export default ApiService;