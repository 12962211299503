import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";
import Select from 'react-select';


function GeneralConfig() {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);


    const [config, setConfig] = useState({
        accounts_allowed: []
    });

    useEffect(() => {
        getConfig();
        getAvailableAccounts();
    }, []);

    async function getConfig() {
        let config = await ApiService.getDefaultConfiguration();

        let selected = config.accounts_allowed.map(email => ({
            value: email,
            label: email
        }));

        setSelectedOption(selected);

    }

    async function getAvailableAccounts() {
        let accounts = await ApiService.getAvailableAccounts();
        let options = accounts.map(email => ({
            value: email,
            label: email
        }));

        setOptions(options);
    }

    function updateConfig() {
        console.log(selectedOption);
        let accounts_allowed = selectedOption.map(option => option.value);
        ApiService.updateDefaultConfiguration({ accounts_allowed });
        alert('Configuration updated successfully');
    }



    return (
        <div className="container">
            <h1>Default configuration</h1>

            <div className="row">
                <div className="col-6">
                    <div className="card">
                        <div className="card-header">
                            Default configuration
                        </div>
                        <div className="card-body">

                            <div className="mt-3">
                                <label className="form-label">Accounts</label>
                                <Select
                                    defaultValue={selectedOption}
                                    isMulti
                                    value={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                />

                            </div>



                            <div className="mt-3">
                                <button
                                    onClick={updateConfig}
                                    className="btn btn-primary">Update</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    );
}

export default GeneralConfig;