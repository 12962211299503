import { useParams } from "react-router-dom";
import ApiService from "../Services/ApiService";
import { useEffect, useState } from "react";

function AccountSendEmail() {
    const { id } = useParams();

    const [emailParams, setEmailParams] = useState({
        from: '',
        to: '',
        subject: '',
        body: ''
    });

    useEffect(() => {
        getAccount();
    }, []);

    async function getAccount() {
        let account = await ApiService.getSMTPAccountDetail(id);
        setEmailParams({
            ...emailParams,
            from: account.email
        });
    }

    async function sendEmail() {
        let { message } = await ApiService.sendSMTPEmail(emailParams);
        alert(message);
    }

    return (
        <div>
            <div className="container">
                <h1>Send test email</h1>

                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header">
                                New account
                            </div>
                            <div className="card-body">

                                <div className="mt-3">
                                    <label className="form-label">From</label>
                                    <input type="text"
                                        value={emailParams.from}
                                        disabled
                                        className="form-control" />
                                </div>

                                <div className="mt-3">
                                    <label className="form-label">To</label>
                                    <input type="text"
                                        value={emailParams.to}
                                        onChange={(e) => setEmailParams({ ...emailParams, to: e.target.value })}
                                        className="form-control" />
                                </div>

                                <div className="mt-3">
                                    <label className="form-label">Subject</label>
                                    <input type="text"
                                        value={emailParams.subject}
                                        onChange={(e) => setEmailParams({ ...emailParams, subject: e.target.value })}
                                        className="form-control" />
                                </div>

                                <div className="mt-3">
                                    <label className="form-label">Body</label>
                                    <textarea type="text"
                                        value={emailParams.body}
                                        rows={5}
                                        onChange={(e) => setEmailParams({ ...emailParams, body: e.target.value })}
                                        className="form-control" >
                                    </textarea>
                                </div>

                                <div className="mt-3">
                                    <button
                                        onClick={sendEmail}
                                        className="btn btn-success">Send email</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    )


}

export default AccountSendEmail;