import { Link } from "react-router-dom"
import ApiService from "../Services/ApiService";
import { useState, useEffect } from "react";
import moment from 'moment';
import PubSub from 'pubsub-js';

function Uploads() {

    let [uploads, setUploads] = useState([]); // state


    const getUploads = async () => {
        showLoadingNotification(true);
        let uploads = await ApiService.getUploads();
        showLoadingNotification(false);
        setUploads(uploads);
    }

    useEffect(() => {
        getUploads(); // Llama a getUploads cuando el componente se monta
    }, []);

    const showLoadingNotification = (show) => {
        if (show) {
            PubSub.publish('show.loading.notification', {
                title: 'Loading...',
                message: 'Getting uploads...',
                show: true
            });
        } else {
            PubSub.publish('show.loading.notification', {
                show: false
            });
        }
    }

    function estimateTimeLeft(upload) {
        if (upload.emails_to_check > 0) {
            let emailsToCheck = upload.emails_to_check;
            let emailsPerSecond = 9;
            let secondsLeft = emailsToCheck * emailsPerSecond;
            let parts = new Date(secondsLeft * 1000).toISOString().slice(11, 19).split(':');
            let hoursLeft = `${parseInt(parts[0])} hours`;
            let minutesLeft = `${parseInt(parts[1])} minutes`;
            return hoursLeft + ' and ' + minutesLeft;
        } else {
            return '';
        }
    }

    async function activateUpload(upload, active) {
        let confirm = window.confirm(`Are you sure you want to ${active ? 'activate' : 'pause'} this upload?`);
        if (!confirm) {
            return;
        }

        let { message } = await ApiService.activateUpload(upload, active);
        alert(message);
        getUploads();
    }


    function renderUploadStatus(upload) {
        if (upload.emails_to_check > 0) {
            return (
                <span className="badge bg-warning text-white ms-2">
                    <i class="fas fa-hourglass-half me-1"></i>
                    Verifying
                </span>
            );
        } else if (upload.emails_to_check === 0) {
            return (
                <span className="badge bg-success ms-2">
                    <i class="fas fa-check-circle me-1"></i>
                    Ready to download
                </span>
            );
        }
    }

    function renderUploadActive(upload) {
        let active = upload.active;
        let has_email_to_check = upload.emails_to_check > 0;

        if (has_email_to_check) {
            if (active === true) {
                return <button className="btn btn-outline-warning"
                    onClick={() => activateUpload(upload._id, false)}
                    style={{ float: 'right' }}>
                    <i class="fas fa-pause me-2"></i>
                    Pause
                </button>
            } else {
                return <button className="btn btn-outline-primary"
                    onClick={() => activateUpload(upload._id, true)}
                    style={{ float: 'right' }}>
                    <i class="fas fa-play me-2"></i>
                    Activate
                </button>
            }
        } else {
            return '';
        }


    }

    // getUploads();

    const renderUploads = () => {
        return uploads.map((upload, index) => (
            <div className="card mb-4">
                <div className="card-body">
                    <div>

                        {renderUploadActive(upload)}

                        <h5>Upload #{index + 1}

                        </h5>


                    </div>

                    <div className="mb-3">
                        <i className="small text-muted">
                            <i className="far fa-file-alt"></i> {upload.filename}
                        </i>
                    </div>
                    <div className="d-flex small">
                        <div>
                            <i class="fas fa-circle text-warning me-1"></i>
                            <i>Emails To Check:</i> <b>{upload.emails_to_check}</b>
                        </div>
                        <div>
                            <i class="fas fa-circle text-info me-1 ms-3"></i>
                            <i>Emails Checked:</i> <b>{upload.emails_checked}</b>
                        </div>
                        <div>
                            <i class="fas fa-circle text-success me-1 ms-3"></i>
                            <i>Emails Valid: </i> <b>{upload.emails_safe}</b>
                        </div>
                        <div>
                            <i class="fas fa-circle text-danger me-1 ms-3"></i>
                            <i>Emails Invalid:</i> <b>{upload.emails_bounced}</b>
                        </div>
                    </div>

                    <hr />

                    <div className="d-flex justify-content-between small">
                        <div>
                            <Link className="fs-14" to={'/uploads/' + upload._id}>
                                <i class="fas fa-info-circle me-1"></i>
                                View details
                            </Link>
                            <a
                                href={'https://api.nobouncy.io/api/nobouncy/uploads/download/' + upload._id}
                                className="btn btn-link fs-14 ms-2">
                                <i class="fas fa-file-csv me-1"></i>
                                Download CSV
                            </a>

                            {renderUploadStatus(upload)}

                        </div>



                        <div className="small text-muted">
                            <i class="far fa-clock me-2"></i>
                            {moment(upload.created_at).format('lll')}
                        </div>

                    </div>

                    {upload && upload.emails_to_check > 0 &&
                        <div className="small text-muted mt-2">
                            <i class="fas fa-hourglass-half me-1"></i>
                            <i>Estimated time left:</i> <b>{estimateTimeLeft(upload)}</b>
                        </div>
                    }
                </div>

            </div>
        ));
    }

    return (
        <div>
            <div className="container">

                <h3 className="mb-4 mt-5">
                    <button
                        onClick={getUploads}
                        className="btn btn-outline-primary btn-sm me-2">
                        <i class="fas fa-sync"></i>
                    </button>
                    Uploads
                </h3>

                {renderUploads()}

            </div>


        </div>

    )
}

export default Uploads