import ApiService from "../Services/ApiService"
import { useState, useEffect } from 'react';
import moment from 'moment';

function Dashboard() {

    let [emails, setEmails] = useState([]);
    let [logs, setLogs] = useState([]);

    const startInitialTimer = async () => {
        let { message } = await ApiService.startInitialTimer();
        alert(message);
    }

    const startBounceTimer = async () => {
        let { message } = await ApiService.startBounceTimer();
        alert(message);
    }

    const getLatestInitialEmailsSent = async () => {
        let emails = await ApiService.getLatestInitialEmailsSent();
        setEmails(emails);
    }

    const getLatestTimersLogs = async () => {
        let logs = await ApiService.getLatestTimersLogs();
        setLogs(logs);
    }

    useEffect(() => {
        getLatestInitialEmailsSent();
        getLatestTimersLogs();
    }, []);


    return (
        <div className="container">
            <h3 className="mb-4 mt-5">Dashboard</h3>

            <div className="row mb-3">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <h5 className="card-title mb-4">Timers</h5>

                            <div className="my-3">
                                <button onClick={startInitialTimer} className="btn btn-outline-primary">
                                    Run initial timer
                                </button>

                                <button onClick={startBounceTimer} className="btn btn-outline-primary ms-2">
                                    Run bounce timer
                                </button>
                            </div>

                            <div className="my-3">

                            </div>


                        </div>
                    </div>
                </div>



            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title mb-4">
                        <button
                            onClick={getLatestInitialEmailsSent}
                            className="btn btn-outline-dark btn-sm me-2">
                            <i className="fas fa-sync"></i>
                        </button>
                        Last emails sent
                    </h5>


                    <table className="table table-bordered small">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <th scope="col">Sent at</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emails.map((email, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{email.from}</td>
                                    <td>{email.email}</td>
                                    <td>{moment(email.initial_email_sent_at).format('lll')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>

            <div className="card">
                <div className="card-body">


                    <h5 className="card-title mb-4">

                        <button
                            onClick={getLatestTimersLogs}
                            className="btn btn-outline-dark btn-sm me-2">
                            <i className="fas fa-sync"></i>
                        </button>
                        Timers logs
                    </h5>

                    {logs.map((log, index) => (
                        <div>
                            <p>Log #{index + 1} - <i className="small">{moment(log.created_at).format('lll')}</i></p>
                            <p style={{ whiteSpace: 'pre-line' }} className="small text-muted">{log.description}</p>
                            <hr />
                        </div>
                    ))}

                </div>
            </div>



        </div>
    )
}

export default Dashboard